<template>
  <div>
    <div class="mt-2">
      <filter-slot
        :filter="filters"
        :filter-principal="filterPrincipal"
        :total-rows="totalRows"
        :paginate="paginate"
        :start-page="startPage"
        :to-page="toPage"
        @reset-all-filters="resetAllFilters"
        @reload="getReports()"
      >
        <template #pagination>
          <b-pagination
            v-model="paginate.currentPage"
            :total-rows="totalRows"
            :per-page="paginate.perPage"
            first-number
            last-number
            class="mb-0 mt-1 mt-sm-0"
            prev-class="prev-item"
            next-class="next-item"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </template>
        <template #custom-vselect>
          <FilterStatusAccount :filters="filters[3]" />
        </template>
        <template #table>
          <div class="p-2">
            <div
              style="
                display: flex;
                justify-content: space-between;
                align-items: center;
              "
              class="mb-1"
            >
              <div>
                <feather-icon
                  icon="SquareIcon"
                  size="13"
                  style="color: #ad1ff2; background-color: #ad1ff2"
                  class="mr-1"
                />
                <span class="mr-2">Total Monthly payments</span>
                <feather-icon
                  icon="SquareIcon"
                  size="13"
                  style="color: #4fcaa6; background-color: #4fcaa6"
                  class="mr-1"
                />
                <span>Total Charges</span>
              </div>

              <b-button
                variant="primary"
                @click="secondStage = !secondStage"
                v-if="!secondStage"
              >
                <feather-icon icon="ArrowRightIcon" size="13" />
              </b-button>

              <b-button
                variant="primary"
                @click="secondStage = !secondStage"
                v-else
              >
                <feather-icon icon="ArrowLeftIcon" size="13" />
              </b-button>
            </div>

            <b-table-simple
              id="my-table"
              small
              sticky-header="75vh"
              responsive
              no-border-collapse
              hover
              bordered
            >
              <b-thead>
                <b-tr class="width-th">
                  <b-th> Client </b-th>
                  <b-th> Account </b-th>
                  <b-th> Program </b-th>
                  <b-th> Start Date </b-th>
                  <b-th> FEE </b-th>
                  <b-th> IP </b-th>
                  <template
                    v-for="date in !secondStage
                      ? totalAmounts[0]
                      : totalAmounts[1]"
                  >
                    <b-th :key="date.id" class="th-bg text-center">{{
                      date.month | myMonthYear
                    }}</b-th>
                  </template>
                </b-tr>
              </b-thead>

              <b-tbody>
                <b-tr v-for="(item, index) in arrayTransactions" :key="index">
                  <b-td>
                    <router-link
                      :class="textLink"
                      :to="{
                        name: 'administration-dashboard',
                        params: {
                          idClient: item.client_account_id,
                        },
                      }"
                      target="_blank"
                    >
                      {{ item.first_name }} {{ item.middle_name }}
                      {{ item.last_name }}
                    </router-link>
                    <br />
                    {{ item.mobile ? item.mobile : "-" }}
                    <br />
                    {{ item.state_text }}
                    <br />
                    {{ item.name }}
                  </b-td>

                  <!-- Account -->
                  <b-td>
                    <span>{{ item.account }}</span>
                    <div>
                      <status-account
                        :account="item"
                        :text="true"
                      ></status-account>
                    </div>
                  </b-td>

                  <!-- Program -->
                  <b-td>{{ item.value }}</b-td>

                  <!-- Start Date -->
                  <b-td>{{ item.start_date | myGlobal }}</b-td>

                  <!-- FEE -->
                  <b-td>
                    <money
                      v-if="item.fee_amount"
                      v-model="item.fee_amount"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      class="v-money-reports"
                      style="background: transparent; border: none"
                      disabled
                    ></money>
                    <span v-else>&nbsp;-</span>
                  </b-td>

                  <!-- IP -->
                  <b-td>
                    <money
                      v-if="item.amount"
                      v-model="item.amount"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      class="v-money-reports"
                      style="background: transparent; border: none;"
                      disabled
                    ></money>
                    <span v-else>&nbsp;-</span>
                  </b-td>

                  <!-- Months -->
                  <template
                    v-for="payment in !secondStage
                      ? item.monthly_payments[0]
                      : item.monthly_payments[1]"
                  >
                    <td :key="payment.id">
                      <div class="d-flex">
                        <money
                          v-model="payment.total"
                          v-bind="{
                            decimal: '.',
                            thousands: ',',
                            prefix: '$ ',
                            precision: 2,
                            masked: false,
                          }"
                          class="v-money-reports text-center"
                          v-if="payment.total"
                          style="
                            background: transparent;
                            border: none;
                            color: #ad1ff2 !important;
                            width:50% !important;
                          "
                          disabled
                        ></money>
                        <span
                          v-else
                          class="text-center"
                          style="width:50%;"
                          :style="`color: ${
                            payment.red == 1 ? 'red;' : 'black;'
                          }`"
                        >
                          {{ payment.red == 1 ? "NOT PAID" : "-" }}
                        </span>
                        <money
                          v-model="payment.charge"
                          v-bind="{
                            decimal: '.',
                            thousands: ',',
                            prefix: '$ ',
                            precision: 2,
                            masked: false,
                          }"
                          class="v-money-reports text-center"
                          v-if="payment.charge"
                          
                          style="
                            background: transparent;
                            border: none;
                            color: #4fcaa6 !important;
                            width:50% !important;
                          "
                          disabled
                        ></money>
                        <span
                          v-else
                          class="text-center"
                          style="width:50%;"
                          :style="`color: ${
                            payment.red == 1 ? 'red;' : 'black;'
                          }`"
                        >
                          {{ "-" }}
                        </span>
                      </div>
                    </td>
                  </template>
                </b-tr>

                <!-- Sub Total -->
                <b-tr>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td></b-td>
                  <b-td>Sub total:</b-td>
                  <b-td
                    style="
                      background: rgb(102, 102, 102);
                      color: rgb(255, 255, 255);
                    "
                  >
                    <money
                      :value="subTotalFee"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      class="v-money-reports-total"
                      style="background: white !important"
                      v-if="subTotalFee"
                      disabled
                    ></money>
                  </b-td>
                  <b-td
                    style="
                      background: rgb(102, 102, 102);
                      color: rgb(255, 255, 255);
                    "
                  >
                    <money
                      :value="subTotalInitialPayment"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      class="v-money-reports-total"
                      style="background: white !important"
                      v-if="subTotalInitialPayment"
                      disabled
                    ></money>
                  </b-td>
                  <b-td
                    v-for="amount in !secondStage
                      ? subTotalAmounts[0]
                      : subTotalAmounts[1]"
                    :key="amount.id"
                    style="
                      background: rgb(102, 102, 102);
                      color: rgb(255, 255, 255) !important;
                    "
                  >
                    <div
                      style=" 
                        color: black;
                        display: flex;
                      "
                    >
                      <money
                        v-model="amount.total"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: '$ ',
                          precision: 2,
                          masked: false,
                        }"
                        class="v-money-reports-total text-center"
                        v-if="amount.total"
                        style="
                          color: #ad1ff2 !important;
                          background: white !important;
                        "
                        disabled
                      ></money>
                      <span v-else class="text-center" style="width:50%;">-</span>
                      <money
                        v-model="amount.charge"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: '$ ',
                          precision: 2,
                          masked: false,
                        }"
                        class="v-money-reports-total text-center"
                        v-if="amount.charge"
                        style="
                          color: #4fcaa6 !important;
                          background: white !important;
                        "
                        disabled
                      ></money>
                      <span v-else class="text-center" style="width:50%;">-</span>
                    </div>
                  </b-td>
                </b-tr>

                <!-- TOTAL -->
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total:</td>
                  <td
                    style="
                      background: rgb(102, 102, 102);
                      color: rgb(255, 255, 255);
                    "
                  >
                    <money
                      :value="totalFee"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      class="v-money-reports-total"
                      style="background: white !important"
                      v-if="totalFee"
                      disabled
                    ></money>
                  </td>
                  <td
                    style="
                      background: rgb(102, 102, 102);
                      color: rgb(255, 255, 255);
                    "
                  >
                    <money
                      :value="totalInitialPayments"
                      v-bind="{
                        decimal: '.',
                        thousands: ',',
                        prefix: '$ ',
                        precision: 2,
                        masked: false,
                      }"
                      class="v-money-reports-total"
                      style="background: white !important"
                      v-if="totalInitialPayments"
                      disabled
                    ></money>
                  </td>
                  <td
                    v-for="amount in !secondStage
                      ? totalAmounts[0]
                      : totalAmounts[1]"
                    :key="amount.id"
                    style="
                      background: rgb(102, 102, 102);
                      color: rgb(255, 255, 255);
                    "
                  >
                    <div
                      style="
                        text-align: center;
                        color: black;
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        flex-direction: column;
                        row-gap: 1rem;
                      "
                    >
                      <money
                        v-model="amount.total"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: '$ ',
                          precision: 2,
                          masked: false,
                        }"
                        class="v-money-reports-total"
                        v-if="amount.total"
                        style="
                          color: #ad1ff2 !important;
                          background: white !important;
                        "
                        disabled
                      ></money>
                      <span v-else>-</span>
                      <money
                        v-if="amount.charge"
                        v-model="amount.charge"
                        v-bind="{
                          decimal: '.',
                          thousands: ',',
                          prefix: '$ ',
                          precision: 2,
                          masked: false,
                        }"
                        class="v-money-reports-total" 
                        style="
                          color: #4fcaa6 !important;
                          background: white !important;
                        "
                        disabled
                      ></money>
                      <span v-else>-</span>
                    </div>
                  </td>
                </tr>
              </b-tbody>
            </b-table-simple>

            <div>
              <feather-icon
                icon="SquareIcon"
                size="13"
                style="color: #ad1ff2; background-color: #ad1ff2"
                class="mr-1"
              />
              <span class="mr-2">Total Monthly payments</span>
              <feather-icon
                icon="SquareIcon"
                size="13"
                style="color: #4fcaa6; background-color: #4fcaa6"
                class="mr-1"
              />
              <span>Total Charges</span>
            </div>
          </div>
        </template>
      </filter-slot>
    </div>
  </div>
</template>
<script>
// Services
import MaReportsService from "@/views/commons/components/reports/reports.service";
import dataFilters from "./components/filters.data";
import StatusAccount from "@/views/commons/components/clients/stylescomponents/StatusAccount.vue";
import NcrLeadsService from "@/views/administration/views/ncr-leads/services/NcrLeads.service.js";
import FilterStatusAccount from "@/views/commons/components/clients/stylescomponents/FilterStatusAccount.vue";
import { mapActions } from "vuex";

export default {
  components: {
    StatusAccount,
    NcrLeadsService,
    FilterStatusAccount,
  },
  data() {
    return {
      secondStage: false,
      arrayTransactions: [],
      filterPrincipal: {
        type: "input",
        inputType: "text",
        placeholder: "Client...",
        model: "",
      },
      totalRows: 0,
      paginate: {
        perPage: 50,
        currentPage: 1,
      },
      toPage: 0,
      isBusy: false,
      perPageOptions: [10, 25, 50, 100],
      startPage: 0,
      filters: dataFilters,
    };
  },
  computed: {
    totalAmounts() {
      if (this.arrayTransactions[0]) {
        return this.arrayTransactions[0].total_amount;
      } else {
        return [[], []];
      }
    },
    subTotalAmounts() {
      if (this.arrayTransactions[0]) {
        return this.arrayTransactions[0].subtotal_amount;
      } else {
        return [[], []];
      }
    },
    subTotalFee() {
      return this.arrayTransactions.reduce(
        (accumulator, current) => accumulator + Number(current.fee_amount),
        0
      );
    },
    subTotalInitialPayment() {
      return this.arrayTransactions.reduce(
        (accumulator, current) => accumulator + Number(current.amount),
        0
      );
    },
    totalFee() {
      if (this.arrayTransactions[0]) {
        return this.arrayTransactions[0].total_fee;
      }
    },
    totalInitialPayments() {
      if (this.arrayTransactions[0]) {
        return this.arrayTransactions[0].total_initial_payments;
      }
    },
  },
  methods: {
    ...mapActions("CrmGlobalStore", ["A_GET_EEUU_STATES"]),
    async getReports() {
      try {
        this.addPreloader();
        const response = await MaReportsService.getPaymentStatusReport({
          orderby: 14,
          order: "desc",
          campo: this.filterPrincipal.model,
          from: this.filters[0].model,
          to: this.filters[1].model,
          filterprogram: this.filters[2].model,
          filterstate: this.filters[4].model,
          filtersourcename: this.filters[5].model,
          filterstatus: this.filters[3].model,
          categ: null,
          currencyid: null,
          vendor_id: null,
          perPage: this.paginate.perPage,
          current_page: this.paginate.currentPage,
        });
        if (response.status == 200) {
          this.arrayTransactions = response.data.data;
          this.startPage = response.data.from;
          this.paginate.currentPage = response.data.current_page;
          this.paginate.perPage = response.data.per_page;
          this.totalRows = response.data.total;
          this.toPage = response.data.to;
        }
        this.removePreloader();
      } catch (error) {
        this.removePreloader();
        throw error;
      }
    },
    resetAllFilters() {
      this.filters.forEach((filter) => {
        filter.model = null;
      });
      this.filterPrincipal.model = null;
      this.getReports();
    },
    async getAllPrograms() {
      try {
        const response = await MaReportsService.getPrograms();

        if (response.status == 200) {
          let tempPrograms = response.data;

          tempPrograms.map((item) => {
            this.filters[2].options.push({
              name: item.value,
              id: item.id,
            });
          });
        }
      } catch (error) {
        throw error;
      }
    },
    getStates() {
      axios
        .post("/api/get-states", {
          type: 1,
        })
        .then((response) => {
          if (response.status == 200) {
            this.states = response.data;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    async getEeuuStates() {
      try {
        const response = await this.A_GET_EEUU_STATES({
          type: 1,
        });

        if (response.status == 200) {
          let tempStates = response.data;
          tempStates.map((item) => {
            this.filters[4].options.push({
              name: item.state,
              id: item.slug,
            });
          });
        }
      } catch (error) {
        console.log("Something went wrong getEeuuStates:", error);
        this.showToast(
          "danger",
          "top-right",
          "Oop!",
          "AlertOctagonIcon",
          this.getInternalErrors(error)
        );
      }
    },
    async getAllStatusClientAccount() {
      this.filters[3].options = [];
      const { data } = await NcrLeadsService.getAllStatusClientAccount();
      this.filters[3].options.push({
        value: 0,
        label: "All",
        icon: "CircleIcon",
      });
      this.filters[3].options.push(...data);
    },
  },
  created() {
    this.getAllPrograms();
    this.getEeuuStates();
    this.getAllStatusClientAccount();
  },
  watch: {
    "paginate.currentPage"() {
      this.getReports();
    },
  },
};
</script>

<style scoped>
.width-th th {
  min-width: 200px;
  color:#ad1ff2
}
</style>
