export default [
  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'From',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },

  {
    type: 'datepicker',
    margin: true,
    showLabel: true,
    label: 'To',
    placeholder: 'Date',
    class: 'font-small-3',
    model: null,
    locale: 'en',
    dateFormatOptions: { year: 'numeric', month: 'numeric', day: 'numeric' },
    cols: 6,
  },

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Program',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'name',
    cols: 12,
  },

  {
    type: "custom-select",
    label: "Client Status",
    margin: true,
    showLabel: true,
    options: [],
    model: '',
    reduce: 'value',
    selectText: 'label',
    cols: 12,
    md: 2,
    visible: true,
  },

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'State',
    model: null,
    options: [],
    reduce: 'id',
    selectText: 'name',
    cols: 6,
  },

  {
    type: 'select',
    margin: true,
    showLabel: true,
    label: 'Source name',
    options: [
      { value: 17, label: 'Facebook' },
      { value: 32, label: 'Google Ads' },
    ],
    model: '',
    reduce: 'value',
    selectText: 'label',
    cols: 6,
    md: 2,
    visible: true,
  },
]
